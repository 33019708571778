import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "a5f770bf-92a2-4b01-9e6b-c4adc0f7dd71",
    videoLibraryId: "106357",
    streamCdnUrl: "https://vz-be757137-65a.b-cdn.net",
    streamApiKey: "153bb0a3-1f6c-45c9-a428cb725f2f-dc23-42dd",
    storageApiPassword: "2a4bb672-1a9c-4d48-8f1fcf9fe5b5-3f81-486a",
    cdnUrl: "https://pa-health-connect.b-cdn.net",
    devFolder: "/pa-health-connect",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
    
    // top level menu 1-bed-example
    assetsMenuOrder: [
        'Media',
    ],

    PAMenuOrder: [
        'videos',
        'renders',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'pdf',
    ],
    // bed1Asset
    MediaAssets: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            "Hospital 360 Demo"
        ],
        panoramasUrl: [
            "https://app.cloudpano.com/tours/AdifnQUfB"
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };